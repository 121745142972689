//import { _ } from "core-js";
import numeral from "numeral";

export const conversions = {
  computed: {
    $_conversions_campaign() {
      return this.$store.getters["conversions/campaign"];
    },
    $_conversions_calls() {
      const getFullName = this.$_conversions_getFullName;
      return this.$store.getters["conversions/calls"].map(function (call) {
        call.date = call.data.date;
        call.nurse_id = call.data.nurse_id;
        call.disposition = call.data.disposition;
        call.name = getFullName(call.conversion);

        return call;
      });
    },
    $_conversions_conversions() {
      return this.$store.getters["conversions/conversions"].conversions || [];
    },
    $_conversions_sessionCount() {
      return this.$store.getters["conversions/conversions"].session_count || 0;
    },
    $_conversions_conversionsByUser() {
      return this.$_conversions_conversions.reduce((acc, obj) => {
        const key = obj["email"];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);

        return acc;
      }, {});
    },
    $_conversions_engagements() {
      return this.$_conversions_conversions.filter((conversion) => {
        return (
          conversion.type === "lead_created" ||
          conversion.type === "lead_created_raw" ||
          conversion.type === "lead_created_phone"
        );
      });
    },
    $_conversions_activations() {
      return this.$_conversions_conversions.filter((conversion) => {
        return conversion.is_won;
      });
    },
    $_conversions_connections() {
      return this.$_conversions_conversions.filter((conversion) => {
        return conversion.is_connected;
      });
    },
    $_conversions_totalEngagements() {
      return this.$_conversions_engagements.length;
    },
    $_conversions_totalActivations() {
      return this.$_conversions_activations.length;
    },
    $_conversions_totalCVR() {
      if (this.$_conversions_sessionCount) {
        return (
          this.$_conversions_totalEngagements / this.$_conversions_sessionCount
        );
      }

      return 0;
    },
    $_conversions_totalCVRLabel() {
      return numeral(this.$_conversions_totalCVR).format("0%");
    },
    $_conversions_activatonCVR() {
      if (this.$_conversions_totalEngagements > 0) {
        return (
          this.$_conversions_totalActivations /
          this.$_conversions_totalEngagements
        );
      }

      return 0;
    },
    $_conversions_activatonCVRLabel() {
      return numeral(this.$_conversions_activatonCVR).format("0%");
    },
    $_conversions_totalPending() {
      return this.$_conversions_getPipeline("is_pending");
    },
    $_conversions_totalNoResponse() {
      return this.$_conversions_getPipeline("is_no_response");
    },
    $_conversions_totalQualified() {
      return this.$_conversions_getPipeline("is_qualified");
    },
    $_conversions_totalUnqualified() {
      return this.$_conversions_getPipeline("is_not_qualified");
    },
    $_conversions_totalPreference() {
      return this.$_conversions_getPipeline("is_preference");
    },
    $_conversions_totalUnknown() {
      return this.$_conversions_getPipeline("is_unknown");
    },
    $_conversions_totalInvalid() {
      return this.$_conversions_getPipeline("is_invalid");
    },
    $_conversions_totalWon() {
      return this.$_conversions_getPipeline("is_won");
    },
    $_conversions_totalConnected() {
      return this.$_conversions_getPipeline("is_connected");
    },
    $_conversions_totalPendingPercentage() {
      return this.$_conversions_getPipelinePercent("is_pending");
    },
    $_conversions_totalNoResponsePercentage() {
      return this.$_conversions_getPipelinePercent("is_no_response");
    },
    $_conversions_totalQualifiedPercentage() {
      return this.$_conversions_getPipelinePercent("is_qualified");
    },
    $_conversions_totalUnqualifiedPercentage() {
      return this.$_conversions_getPipelinePercent("is_unqualified");
    },
    $_conversions_totalPreferencePercentage() {
      return this.$_conversions_getPipelinePercent("is_preference");
    },
    $_conversions_totalUnknownPercentage() {
      return this.$_conversions_getPipelinePercent("is_unknown");
    },
    $_conversions_totalInvalidPercentage() {
      return this.$_conversions_getPipelinePercent("is_invalid");
    },
    $_conversions_totalWonPercentage() {
      return this.$_conversions_getPipelinePercent("is_won");
    },
    $_conversions_totalConnectedPercentage() {
      return this.$_conversions_getPipelinePercent("is_connected");
    },
    $_conversions_predictions() {
      return this.$store.getters["conversions/conversions"].predictions || null;
    },
    $_conversions_predictions_total() {
      if (this.$_conversions_predictions) {
        return this.$_conversions_predictions.reduce((total, val) => {
          return total + val.total;
        }, 0);
      }
    },
    $_conversions_predictions_confidenceIntervals() {
      if (this.$_conversions_predictions) {
        return this.$_conversions_predictions.map((item) => {
          return {
            total: item.total,
            label: item.label
          }
        });
      }
    },
    $_conversions_predictions_estimatedLeadsQualified() {
      if (this.$_conversions_predictions_total) {
        return Math.round(this.$_conversions_predictions_total * (this.$_conversions_totalQualified / (this.$_conversions_totalQualified + this.$_conversions_totalUnqualified)));
      }

      return 0;
    },
    $_conversions_predictions_estimatedLeadsQualifiedLabel() {
      return numeral(this.$_conversions_predictions_estimatedLeadsQualified).format('0,0');
    },
    $_conversions_predictions_estimatedLeadsActivated() {
      if (this.$_conversions_predictions_total) {
        return Math.round(this.$_conversions_predictions_total * (this.$_conversions_totalWon / (this.$_conversions_totalQualified + this.$_conversions_totalUnqualified)));
      }

      return 0;
    },
    $_conversions_predictions_estimatedLeadsActivatedLabel() {
      return numeral(this.$_conversions_predictions_estimatedLeadsActivated).format('0,0');
    },
    $_conversions_predictions_baseTotal() {
      return this.$_conversions_totalUnknown - this.$_conversions_totalPending;
    },
    $_conversions_predictions_estimatedLeadsQualifiedPercentage() {
      if (this.$_conversions_predictions_baseTotal) {
        return this.$_conversions_predictions_estimatedLeadsQualified / this.$_conversions_predictions_baseTotal;
      }

      return 0;
    },
    $_conversions_predictions_estimatedLeadsActivatedPercentage() {
      if (this.$_conversions_predictions_baseTotal) {
        return this.$_conversions_predictions_estimatedLeadsActivated / this.$_conversions_predictions_baseTotal;
      }

      return 0;
    },
    $_conversions_predictions_estimatedLeadsQualifiedPercentageLabel() {
      return numeral(this.$_conversions_predictions_estimatedLeadsQualifiedPercentage).format('0%');
    },
    $_conversions_predictions_estimatedLeadsActivatedPercentageLabel() {
      return numeral(this.$_conversions_predictions_estimatedLeadsActivatedPercentage).format('0%');
    },
  },
  methods: {
    $_conversions_getConversions(campaign_id, provider_id, range) {
      return this.$store.dispatch("conversions/getConversions", {
        campaign_id,
        provider_id,
        range,
      });
    },
    $_conversions_getCalls(campaign_id, provider_id, nurse_id, range) {
      return this.$store.dispatch("conversions/getCalls", {
        campaign_id,
        provider_id,
        nurse_id,
        range,
      });
    },
    $_conversions_getFullName(conversion) {
      if (!conversion.first_name && conversion.type == "lead_created_phone") {
        return "Inbound Lead";
      }
      return `${conversion.first_name} ${conversion.last_name}`;
    },
    $_conversions_getType(conversion) {
      if (conversion.type === "lead_created_raw") {
        return "Raw";
      } else {
        return "Qualified";
      }
    },
    $_conversions_getDisposition(conversion) {
      const lastAction = conversion.actions.at(-1);
      if (lastAction) {
        return lastAction.data.disposition;
      }

      return "Pending";
    },
    $_conversions_getPipelinePercent(flag) {
      const total = this.$_conversions_getPipeline(flag);
      let percentage = 0;

      if (total) {
        percentage = total / this.$_conversions_totalEngagements;
      }

      return numeral(percentage).format("0%");
    },
    $_conversions_getPipeline(flag) {
      return this.$_conversions_conversions.reduce((total, conversion) => {
        if (conversion[flag] === true) {
          return (total += 1);
        }

        return total;
      }, 0);
    },
  },
};
